import { Injectable } from '@angular/core';
import { UserNode } from '../../core/user/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    Inspection,
    InspectionPaginate,
    ServiceOrder,
    ServiceOrderPaginate,
    SpaceAnswer,
    Category,
    ServiceLevelAgreement,
    ServiceOrderCategoryPaginate,
    ServiceOrderTypeActionPaginate,
    TypeAction,
    ConfigurationManager,
    Origin,
    Status,
    SubTask,
    Task,
    TasksPaginate,
    ServiceOrderMessage,
    Periodicity,
    TaskMessage,
    SpaceAnswerFile,
    AllManager,
    ServiceOrderItem,
    ServiceOrderMaterialSolicitationRequirement, CategoryPurchase,
    Calendar,
    CalendarEvent,
    CalendarSettings,
    Store
} from './activity.types';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../core/user/user.service';
import { environment } from '../../../environments/environment';
import { map, switchMap, take, tap } from 'rxjs/operators';
import {Inventory, InventoryPaginate, InventoryCategory} from '../inventory/inventory.types';
import { Moment } from 'moment';
import { TaskIntervals, TaskRecurrences } from './activity.enum';
@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    private _task: BehaviorSubject<Task | null> = new BehaviorSubject(null);
    private _tasks: BehaviorSubject<Task[] | null> = new BehaviorSubject([]);
    private _taskMessage: BehaviorSubject<TaskMessage | null> = new BehaviorSubject(null);
    private _taskMessages: BehaviorSubject<TaskMessage[] | null> = new BehaviorSubject([]);
    private _subTask: BehaviorSubject<SubTask | null> = new BehaviorSubject(null);
    private _subTasks: BehaviorSubject<SubTask[] | null> = new BehaviorSubject([]);
    private _serviceOrder: BehaviorSubject<ServiceOrder | null> = new BehaviorSubject(null);
    private _store: BehaviorSubject<Store | null> = new BehaviorSubject(null);
    private _serviceOrderMessage: BehaviorSubject<ServiceOrderMessage | null> = new BehaviorSubject(null);
    private _serviceOrderMessages: BehaviorSubject<ServiceOrderMessage[] | null> = new BehaviorSubject([]);
    private _serviceOrderItems: BehaviorSubject<ServiceOrderItem[] | null> = new BehaviorSubject([]);
    _serviceOrders: BehaviorSubject<ServiceOrder[] | null> = new BehaviorSubject([]);
    private _inspection: BehaviorSubject<Inspection | null> = new BehaviorSubject(null);
    private _inspections: BehaviorSubject<Inspection[] | null> = new BehaviorSubject([]);
    private _spaceAnswer: BehaviorSubject<SpaceAnswer | null> = new BehaviorSubject(null);
    private _spaceAnswers: BehaviorSubject<SpaceAnswer[] | null> = new BehaviorSubject([]);
    private _serviceOrderTypeAction: BehaviorSubject<TypeAction[] | null> = new BehaviorSubject([]);
    private _serviceOrderCategory: BehaviorSubject<Category[] | null> = new BehaviorSubject([]);
    private _serviceOrderOrigin: BehaviorSubject<Origin[] | null> = new BehaviorSubject([]);
    private _serviceOrderMaterialSolicitationRequirement: BehaviorSubject<ServiceOrderMaterialSolicitationRequirement[] | null> = new BehaviorSubject([]);
    private _categoryPurchase: BehaviorSubject<CategoryPurchase[] | null> = new BehaviorSubject([]);
    private _inventoryCategory: BehaviorSubject<InventoryCategory[] | null> = new BehaviorSubject([]);
    private _configurationManager: BehaviorSubject<ConfigurationManager | null> = new BehaviorSubject(null);
    private _allManager: BehaviorSubject<any[] | null> = new BehaviorSubject([]);

    // Calendar
    private _calendars: BehaviorSubject<Calendar[] | null> = new BehaviorSubject([]);
    private _events: BehaviorSubject<CalendarEvent[] | null> = new BehaviorSubject([]);
    private _recurrentEvents: BehaviorSubject<CalendarEvent[] | null> = new BehaviorSubject([]);
    private _loadedEventsRange: { start: Moment | null, end: Moment | null } = {
        start: null,
        end  : null
    };

    private readonly _numberOfDaysToPrefetch = 60;
    private _user: UserNode;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
        this._userService.user$
            .subscribe((user) => this._user = user);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for Service Order
     */
    get serviceOrder$(): Observable<ServiceOrder> {
        return this._serviceOrder.asObservable();
    }

    /**
     * Getter for Service Order Category Purchase
     */
    get categoryPurchase$(): Observable<CategoryPurchase[]> {
        return this._categoryPurchase.asObservable();
    }

    /**
     * Getter for Inventory Category
     */
    get inventoryCategory$(): Observable<InventoryCategory[]> {
        return this._inventoryCategory.asObservable();
    }

    /**
     * Getter for Service Order Material Solicitation Requirement
     */
    get serviceOrderMaterialSolicitationRequirement$(): Observable<ServiceOrderMaterialSolicitationRequirement[]> {
        return this._serviceOrderMaterialSolicitationRequirement.asObservable();
    }

    /**
     * Getter for Service Orders
     */
    get serviceOrders$(): Observable<ServiceOrder[]> {
        return this._serviceOrders.asObservable();
    }

    /**
     * Getter for Service Orders
     */
    get store$(): Observable<Store> {
        return this._store.asObservable();
    }

    /**
     * Getter for Inspection
     */
    get inspection$(): Observable<Inspection> {
        return this._inspection.asObservable();
    }

    /**
     * Getter for Inspections
     */
    get inspections$(): Observable<Inspection[]> {
        return this._inspections.asObservable();
    }

    /**
     * Getter for Space Answer
     */
    get spaceAnswer$(): Observable<SpaceAnswer> {
        return this._spaceAnswer.asObservable();
    }

    /**
     * Getter for Space Answers
     */
    get spaceAnswers$(): Observable<SpaceAnswer[]> {
        return this._spaceAnswers.asObservable();
    }

    /** Getter for Service Orders Messages
     */
    get serviceOrderMessages$(): Observable<ServiceOrderMessage[]> {
        return this._serviceOrderMessages.asObservable();
    }

    /** Getter for Service Orders Messages
     */
    get serviceOrderItems$(): Observable<ServiceOrderItem[]> {
        return this._serviceOrderItems.asObservable();
    }

    /**
     * Getter for Service Orders Message
     */
    get serviceOrderMessage$(): Observable<ServiceOrderMessage> {
        return this._serviceOrderMessage.asObservable();
    }

    /**
     * Getter for Service Order Manager Configuration
     */
    get managerConfiguration$(): Observable<ConfigurationManager> {
        return this._configurationManager.asObservable();
    }

    /**
     * Getter for Service Order Type Action
     */
    get serviceOrderTypeAction$(): Observable<TypeAction[]> {
        return this._serviceOrderTypeAction.asObservable();
    }

    /**
     * Getter for Service Order Categories
     */
    get serviceOrderCategory$(): Observable<Category[]> {
        return this._serviceOrderCategory.asObservable();
    }

    /**
     * Getter for Service Order Origin
     */
    get serviceOrderOrigin$(): Observable<Origin[]> {
        return this._serviceOrderOrigin.asObservable();
    }

    /**
     * Getter for tasks
     */
    get tasks$(): Observable<Task[]> {
        return this._tasks.asObservable();
    }

    /**
     * Getter for task messages
     */
    get taskMessages$(): Observable<TaskMessage[]> {
        return this._taskMessages.asObservable();
    }

    /**
     * Getter for task message
     */
    get taskMessage$(): Observable<TaskMessage> {
        return this._taskMessage.asObservable();
    }

    /**
     * Getter for subtasks
     */
    get subTasks$(): Observable<SubTask[]> {
        return this._subTasks.asObservable();
    }

    /**
     * Getter for all manager
     */
    get allManager$(): Observable<any[]> {
        return this._allManager.asObservable();
    }

    /**
     * Getter for events
     */
    get events$(): Observable<CalendarEvent[]>
    {
        return this._events.asObservable();
    }

    /**
     * Getter for recurrent event
     */
    get recurrentEvents$(): Observable<CalendarEvent[]>
    {
        return this._recurrentEvents.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all Service Order
     *
     * @param page
     * @param limit
     * @param search
     * @param responsible
     * @param category
     */
    getAllServiceOrder(page?: number, limit: number = environment.pageSize, search?: string, responsible?: number, category?: number): Observable<ServiceOrderPaginate> {
        return this._httpClient.get<ServiceOrderPaginate>(`${environment.api}/service-order?page=${page}&limit=${limit}&search=${search}&status=live${responsible ? '&responsible=' + responsible : ''}${category ? '&category=' + category : ''}`, {
            reportProgress: true
        }).pipe(
            tap((paginate: ServiceOrderPaginate) => {

                // Update Service Orders
                if (page > 1) {
                    this._serviceOrders.next([...this._serviceOrders.value, ...paginate.items]);
                } else {
                    this._serviceOrders.next([...paginate.items]);
                }
                // Return paginate
                return paginate;
            })
        );
    }

    /**
     * Get one Service Order
     *
     * @param serviceOrderId
     */
    getServiceOrder(serviceOrderId: number): Observable<ServiceOrder> {
        return this._httpClient.get<ServiceOrder>(`${environment.api}/service-order/${serviceOrderId}`, {
            reportProgress: true
        }).pipe(
            tap((serviceOrder) => {
                // Return Service Order
                return serviceOrder;
            })
        );
    }

    /**
     * Get all report
     *
     * @param startDate
     * @param endDate
     */
    getAllReport(startDate: string = '', endDate: string = ''): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.api}/tasks/report?start_date=${startDate}&end_date=${endDate}`, {
            reportProgress: true
        }).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    /**
     * Get all Type Action
     */
    getAllServiceOrderTypeAction(page?: number, limit: number = environment.pageSize, type: number = 1): Observable<ServiceOrderTypeActionPaginate> {
        return this._httpClient.get<ServiceOrderTypeActionPaginate>(`${environment.api}/service-order-type-action?type=${type}`, {
            reportProgress: true
        }).pipe(
            tap((paginate: ServiceOrderTypeActionPaginate) => {
                // Update Service Orders
                this._serviceOrderTypeAction.next(paginate.items);

                // Return paginate
                return paginate;
            })
        );
    }

    /**
     * Get all Type Action
     */
    getAllTasksTypeAction(page?: number, limit: number = environment.pageSize, type: number = 0): Observable<ServiceOrderTypeActionPaginate> {
        return this._httpClient.get<ServiceOrderTypeActionPaginate>(`${environment.api}/service-order-type-action?type=${type}`, {
            reportProgress: true
        }).pipe(
            tap((paginate: ServiceOrderTypeActionPaginate) => {
                // Update Service Orders
                this._serviceOrderTypeAction.next(paginate.items);

                // Return paginate
                return paginate;
            })
        );
    }

    /**
     * Get all Task Categories
     */
    getAllTaskCategories(page?: number, limit: number = environment.pageSize, type: number = 0): Observable<ServiceOrderCategoryPaginate> {
        return this._httpClient.get<ServiceOrderCategoryPaginate>(`${environment.api}/service-order-categories?type=${type}&limit=${limit}`, {
            reportProgress: true
        }).pipe(
            tap((paginate: ServiceOrderCategoryPaginate) => {
                // Return paginate
                return paginate;
            })
        );
    }

    /**
     * Get all Service Order Categories
     */
    getAllServiceOrderCategory(page?: number, limit: number = environment.pageSize, type: number = 1): Observable<ServiceOrderCategoryPaginate> {
        return this._httpClient.get<ServiceOrderCategoryPaginate>(`${environment.api}/service-order-categories?type=${type}`, {
            reportProgress: true
        }).pipe(
            tap((paginate: ServiceOrderCategoryPaginate) => {
                // Update Service Orders
                this._serviceOrderCategory.next(paginate.items);

                // Return paginate
                return paginate;
            })
        );
    }

    /**
     * Get all Manager Configuration
     */
    getAllManagerConfiguration(): Observable<ConfigurationManager> {
        return this._httpClient.get<ConfigurationManager>(`${environment.api}/configuration-manager`, {
            reportProgress: true
        }).pipe(
            tap((configurationManager: ConfigurationManager) => {
                // Return space
                this._configurationManager.next(configurationManager);
                return configurationManager;
            })
        );
    }

    /**
     * Get all Origins
     */
    getAllServiceOrderOrigins(): Observable<Origin[]> {
        return this._httpClient.get<Origin[]>(`${environment.api}/origins?type=1`, {
            reportProgress: true
        }).pipe(
            tap((serviceOrderOrigin: Origin[]) => {
                // Return space
                this._serviceOrderOrigin.next(serviceOrderOrigin);
                return serviceOrderOrigin;
            })
        );
    }

    /**
     * Get all Category Purchase
     */
    getAllCategoryPurchase(): Observable<CategoryPurchase[]> {
        return this._httpClient.get<CategoryPurchase[]>(`${environment.api}/service-order-category-purchase`, {
            reportProgress: true
        }).pipe(
            tap((categoryPurchase: CategoryPurchase[]) => {
                // Return space
                this._categoryPurchase.next(categoryPurchase);
                return categoryPurchase;
            })
        );
    }

    /**
     * Get all Inventory Categories
     */
    getAllInventoryCategories(): Observable<InventoryCategory[]> {
        return this._httpClient.get<InventoryCategory[]>(`${environment.api}/inventory-categories`, {
            reportProgress: true
        }).pipe(
            tap((inventoryCategories: InventoryCategory[]) => {
                // Return space
                this._inventoryCategory.next(inventoryCategories);
                return inventoryCategories;
            })
        );
    }

    /**
     * Get all Service Order Material Solicitation Requirement
     */
    getAllServiceOrderMaterialSolicitationRequirement(): Observable<ServiceOrderMaterialSolicitationRequirement[]> {
        return this._httpClient.get<ServiceOrderMaterialSolicitationRequirement[]>(`${environment.api}/service-order-material-solicitation-requirements`, {
            reportProgress: true
        }).pipe(
            tap((serviceOrderMaterialSolicitationRequirement: ServiceOrderMaterialSolicitationRequirement[]) => {
                // Return space
                this._serviceOrderMaterialSolicitationRequirement.next(serviceOrderMaterialSolicitationRequirement);
                return serviceOrderMaterialSolicitationRequirement;
            })
        );
    }


    /**
     * Get all Origins
     */
    getAllTaskOrigins(): Observable<Origin[]> {
        return this._httpClient.get<Origin[]>(`${environment.api}/origins?type=0`, {
            reportProgress: true
        }).pipe(
            tap((taskOrigins: Origin[]) => {
                // Return space
                this._serviceOrderOrigin.next(taskOrigins);
                return taskOrigins;
            })
        );
    }

    /**
     * Create Service Order
     *
     */
    createServiceOrder(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
        return this.serviceOrders$.pipe(
            take(1),
            switchMap((serviceOrders) => this._httpClient.post<ServiceOrder>(`${environment.api}/service-order`, serviceOrder, {
                reportProgress: true,
            }).pipe(
                map((newServiceOrder) => {

                    // Update Service Order
                    this._serviceOrders.next([newServiceOrder, ...serviceOrders]);

                    // Return the new Service Order
                    return newServiceOrder;
                })
            ))
        );
    }

    /**
     * Create Service Order
     *
     */
    createStore(store: any): Observable<any> {
        return this.serviceOrders$.pipe(
            take(1),
            switchMap(() => this._httpClient.post<any>(`${environment.api}/service-order-material-solicitation`, store, {
                reportProgress: true,
            }).pipe(
                map((newStore) => {


                    // Return the new Service Order
                    return newStore;
                })
            ))
        );
    }

    /**
     * Create Service Order Message
     *
     */
    createServiceOrderMessage(serviceOrderMessage: { service_order_id: number; content: any}, allManager: boolean = false): Observable<ServiceOrderMessage> {
        return (
            allManager ?
                this.allManager$ : this.serviceOrderMessages$
        ).pipe(
            take(1),
            switchMap((serviceOrderMessages) => this._httpClient.post<ServiceOrderMessage>(`${environment.api}/service-order-messages`, serviceOrderMessage, {
                reportProgress: true,
            }).pipe(
                map((newServiceOrderMessage) => {

                    // Update Service Order Message
                    this._serviceOrderMessages.next([newServiceOrderMessage, ...serviceOrderMessages]);

                    const serviceOrderIndex = allManager ?
                        this._allManager.value.findIndex(serviceOrder => serviceOrder.type === 1 && serviceOrder.id === newServiceOrderMessage.service_order_id) :
                        this._serviceOrders.value.findIndex(serviceOrder => serviceOrder.id === newServiceOrderMessage.service_order_id);

                    allManager ? this._allManager.value[serviceOrderIndex]?.serviceOrderMessage?.unshift(newServiceOrderMessage) : this._serviceOrders.value[serviceOrderIndex]?.serviceOrderMessage?.unshift(newServiceOrderMessage);

                    // Return the new Service Order Message
                    return newServiceOrderMessage;

                })
            ))
        );
    }

    /**
     * Update Service Order
     *
     * @param id
     * @param ServiceOrder
     */
    updateServiceOrder(id: number, serviceOrderChange: any, allManager: boolean = false): Observable<any> {
        return(
            allManager ? this.allManager$ : this.serviceOrders$
        ).pipe(
                take(1),
                switchMap(serviceOrders => this._httpClient.patch<any>(`${environment.api}/service-order/${id}`,
                    serviceOrderChange,
                    {
                        reportProgress: true
                    }).pipe(
                    map((updateServiceOrder) => {
                        // Find the index of the updated space
                        const serviceOrderIndex = allManager ? serviceOrders.findIndex(item => item.type === 1 && item.id === id) : serviceOrders.findIndex(item => item.id === id);

                        // Update the space
                        serviceOrders[serviceOrderIndex] = allManager ? {...updateServiceOrder, type: 1} : updateServiceOrder;

                        // Update the spaces
                        allManager ? this._allManager.next(serviceOrders) : this._serviceOrders.next(serviceOrders);

                        // Return the updated question
                        return updateServiceOrder;
                    })
                ))
            );
    }

    /**
     * Update Service Order Message
     *
     * @param id
     * @param ServiceOrderMessage
     */
    updateServiceOrderMessage(id: number, serviceOrderMessagesChange: any): Observable<any> {
        return this.serviceOrderMessages$.pipe(
            take(1),
            switchMap(serviceOrderMessages => this._httpClient.patch<any>(`${environment.api}/service-order-messages/${id}`,
                serviceOrderMessagesChange,
                {
                    reportProgress: true
                }).pipe(
                    map((updateServiceOrderMessage) => {
                        // Find the index of the updated space
                        const serviceOrderMessageIndex = serviceOrderMessages.findIndex(item => item.id === id);

                        // Update the space
                        serviceOrderMessages[serviceOrderMessageIndex] = updateServiceOrderMessage;

                        // Update the spaces
                        this._serviceOrderMessages.next(serviceOrderMessages);

                        // Return the updated question
                        return updateServiceOrderMessage;
                    })
                ))
        );
    }

    getTask(id: number): Observable<Task>
    {
        return this._httpClient.get<Task>(`${environment.api}/tasks/${id}`, {
            reportProgress: true
        }).pipe(
            tap((response: Task) => {
                // Return response
                return response;
            })
        );
    }

    /**
     * Get all tasks
     */
    getAllTasks(page: number = 1, search: string = '', limit: number = environment.pageSize, status: string = '', responsible: number, category: number): Observable<TasksPaginate> {
        return this._httpClient.get<TasksPaginate>(`${environment.api}/tasks?page=${page}&limit=${limit}&search=${search}&status=${status}${responsible ? '&responsible=' + responsible : ''}${category ? '&category=' + category : ''}`, {
            reportProgress: true
        }).pipe(
            tap((paginate: TasksPaginate) => {
                // Update paginate
                if (page > 1) {
                    this._tasks.next([...this._tasks.value, ...paginate.items]);
                } else {
                    this._tasks.next([...paginate.items]);
                }

                // Return paginate
                return paginate;
            })
        );
    }

    /**
     * Get all inspections
     *
     * @param page
     * @param limit
     * @param search
     * @param responsible
     */
    getAllInspections(page: number = 1, limit: number = environment.pageSize, search: string = '', responsible: number): Observable<InspectionPaginate> {
        return this._httpClient.get<InspectionPaginate>(`${environment.api}/inspection/?page=${page}&limit=${limit}&search=${search}&status=live${responsible ? '&responsible=' + responsible : ''}`, {
            reportProgress: true
        }).pipe(
            tap((paginate: InspectionPaginate) => {

                // Update paginate
                if (page > 1) {
                    this._inspections.next([...this._inspections.value, ...paginate.items]);
                } else {
                    this._inspections.next([...paginate.items]);
                }

                // Return paginate
                return paginate;
            })
        );
    }

    /**
     * Create task
     * @param task
     * @returns
     */
    createTask(task: Task): Observable<Task> {
        return this.tasks$.pipe(
            take(1),
            switchMap((currentTasks) => this._httpClient.post<Task>(`${environment.api}/tasks`, task, {
                reportProgress: true,
            }).pipe(
                map((newTask) => {
                    // Prepare event object for calendar
                    const endDate = new Date(newTask.estimated_date);
                    endDate.setDate(endDate.getDate() + 1);
                    const event: CalendarEvent = {
                        id: newTask.id,
                        title: newTask.title,
                        description: newTask.description,
                        start: newTask.created_at,
                        end: endDate,
                        isRecurrent: false,
                        allDay: true,
                        classNames: ['default-event'],
                        task: newTask,
                    };

                    // Verify if task is recurrent
                    if (task.periodicity_id) {
                        const untilDate = new Date(new Date(newTask.created_at).getFullYear() + 1, 0);
                        event.classNames = ['recurrent-event'];
                        event.textColor = '#009CDE';
                        event.isRecurrent = true;
                        event.rrule = {
                            freq: TaskRecurrences[newTask.periodicity.name],
                            interval: TaskIntervals[newTask.periodicity.name],
                            dtstart: newTask.estimated_date,
                            until: untilDate,
                        };

                        // Add recurrent event to calendar recurrent events
                        this._recurrentEvents.next([...this._recurrentEvents.value, event]);
                    } else {
                        // Add event to calendar events
                        this._events.next([...this._events.value, event]);
                    }

                    // Sort tasks by priority DESC
                    const sortedTasks = [newTask, ...currentTasks];
                    // sortedTasks.sort((a, b) => b.priority - a.priority);

                    // Update the tasks with the new task
                    this._tasks.next(sortedTasks);

                    // Return the new task
                    return newTask;
                })
            ))
        );
    }

    /**
     * Create Task Message
     * @param body
     * @param allManager
     */
    createTaskMessage(body, allManager: boolean = false): Observable<TaskMessage> {
        return (
            allManager ? this.allManager$ : this.tasks$
        ).pipe(
            take(1),
            switchMap((current) => this._httpClient.post<TaskMessage>(`${environment.api}/task-messages`, body, {
                reportProgress: true,
            }).pipe(
                map((newTaskMessage) => {
                    // Update task messages
                    this._taskMessages.next([newTaskMessage, ...current]);

                    // Find current task index
                    // const taskIndex = allManager ? this._allManager.value.findIndex(item => item.type === 0 && item.id === newTaskMessage.task_id) : this._tasks.value.findIndex(task => task.id === newTaskMessage.task_id);
                    //
                    // Update selected task messages
                    // allManager ? this._allManager.value[taskIndex].taskMessages.unshift(newTaskMessage) : this._tasks.value[taskIndex].taskMessages.unshift(newTaskMessage);

                    // Return the new task message
                    return newTaskMessage;

                })
            ))
        );
    }

    /**
     * Update Task
     * @param taskId
     * @param body
     * @param dragged
     * @param allManager
     * @returns
     */
    updateTask(taskId: number, body: Partial<Task>, dragged: boolean = false, allManager: boolean = false): Observable<Task> {
        return (
            allManager ? this.allManager$ : this.tasks$
        ).pipe(
            take(1),
            switchMap((current) => this._httpClient.patch<Task>(`${environment.api}/tasks/${taskId}`, body, {
                reportProgress: true,
            }).pipe(
                map((updatedTask) => {
                    // Find the index of the updated task
                    const taskIndex = allManager ? current.findIndex(item => item.type === 0 && item.id === taskId) : current.findIndex(item => item.id === taskId);

                    // Find the index of the updated event
                    const eventIndex = updatedTask.periodicity ? this._recurrentEvents.value.findIndex(evt => evt.id === taskId) : this._events.value.findIndex(evt => evt.id === taskId);

                    // Maintain current sub-tasks from accessor
                    updatedTask.sub_tasks = current[taskIndex].sub_tasks;

/*
                    // Maintain current task messages from accessor
                    updatedTask.taskMessages = current[taskIndex].taskMessages;
*/

                    // Update the task
                    current[taskIndex] = allManager ? {...updatedTask, type: 0} : updatedTask;

                    if (!dragged && !allManager) {
                        // Sort tasks by priority DESC
                        current.sort((a, b) => b.priority - a.priority);
                    }

                    // Prepare event object for calendar
                    const endDate = new Date(updatedTask.estimated_date);
                    endDate.setDate(endDate.getDate() + 1);
                    const event: CalendarEvent = {
                        id: updatedTask.id,
                        title: updatedTask.title,
                        description: updatedTask.description,
                        start: updatedTask.created_at,
                        end: endDate,
                        isRecurrent: false,
                        allDay: true,
                        classNames: ['default-event'],
                        task: updatedTask,
                    };

                    // Verify if task is recurrent
                    if (updatedTask.periodicity) {
                        // const untilDate = moment(updatedTask.created_at).add(1, 'year').format('YYYY-MM');
                        const untilDate = new Date(new Date(updatedTask.created_at).getFullYear() + 1, 0);
                        event.classNames = ['recurrent-event'];
                        event.textColor = '#009CDE';
                        event.isRecurrent = true;
                        event.rrule = {
                            freq: TaskRecurrences[updatedTask.periodicity.name],
                            interval: TaskIntervals[updatedTask.periodicity.name],
                            dtstart: updatedTask.estimated_date,
                            until: untilDate,
                        };

                        // Get the recurrent events
                        const events = this._recurrentEvents.value;

                        // Replace the old recurrent event
                        events.splice(eventIndex, 1, event);

                        // Update the recurrent events
                        this._recurrentEvents.next(events);
                    } else {
                        // Get the events
                        const events = this._events.value;

                        // Replace the old event
                        events.splice(eventIndex, 1, event);

                        // Update the events
                        this._events.next(events);
                    }

                    console.log(this._events.value, event);


                    // Update the tasks
                    allManager ? this._allManager.next(current) : this._tasks.next(current);

                    // Return the updated task
                    return updatedTask;
                })
            ))
        );
    }

    /**
     * Delete task
     * @param taskId
     * @param allManager
     */
    deleteTask(taskId: number, allManager: boolean = false): Observable<void> {
        return (
            allManager ? this.allManager$ : this.tasks$
        ).pipe(
            take(1),
            switchMap((current) => this._httpClient.delete<void>(`${environment.api}/tasks/${taskId}`, {
                reportProgress: true,
            }).pipe(
                map(() => {
                    // Find the index of the task
                    const taskIndex = allManager ? current.findIndex(item => item.type === 0 && item.id === taskId) : current.findIndex(item => item.id === taskId);

                    // Remove the task
                    current.splice(taskIndex, 1);

                    // Update the tasks
                    allManager ? this._allManager.next(current) : this._tasks.next(current);
                })
            ))
        );
    }

    /**
     * Get one inspection
     *
     * @param inspectionId
     */
    getInspection(inspectionId: number): Observable<Inspection> {
        return this._httpClient.get<Inspection>(`${environment.api}/inspection/${inspectionId}`, {
            reportProgress: true
        }).pipe(
            tap((inspection: Inspection) => {

                // Update Inspection
                this._inspection.next(inspection);

                // Return paginate
                return inspection;
            })
        );
    }

    getInspectionByQrCode(qrcode: string): Observable<Inspection> {
        return this._httpClient.get<Inspection>(`${environment.api}/inspection/code/${qrcode}`, {
            reportProgress: true
        }).pipe(
            tap((inspection: Inspection) => {

                // Update Inspection
                this._inspection.next(inspection);

                // Return paginate
                return inspection;
            })
        );
    }

    /**
     * Create inspetions
     */
    createInspection(spaceId: number): Observable<Inspection> {
        return this._httpClient.post<Inspection>(`${environment.api}/inspection`, {space_id: spaceId}, {
            reportProgress: true
        }).pipe(
            tap((inspection: Inspection) => {
                // Update Inspection
                this._inspection.next(inspection);

                // Return paginate
                return inspection;
            })
        );
    }

    /** Update Inspection
     *
     * @param id
     * @param body
     */
    updateInspection(id: number, body: any): Observable<Inspection> {
        return this.inspections$.pipe(
            take(1),
            switchMap(inspections => this._httpClient.patch<Inspection>(`${environment.api}/inspection/${id}`,
                    body,
                    {
                        reportProgress: true
                    }).pipe(
                    tap((updatedInspection) => {

                        // Find Inspection Index
                        const index = inspections.findIndex(x => x.id === updatedInspection.id);

                        // Update Inspection
                        inspections[index] = updatedInspection;
                        this._inspection.next(updatedInspection);

                        // Remove if inspection is finished
                        if (updatedInspection.is_finished) {
                            inspections.splice(index, 1);
                        }

                        // Update Inspections
                        this._inspections.next(inspections);

                        // Return updated inspection
                        return updatedInspection;
                    })
                )
            ));
    }


    /** Get all status
     * @param type
     */
    getAllStatus(type: number = 1): Observable<Status[]> {
        return this._httpClient.get<Status[]>(`${environment.api}/manager-status?type=${type}`, {
            reportProgress: true
        }).pipe(
            tap((status: Status[]) => {
                return status;
            })
        );
    }

    /**
     * Update task status
     * @param task_id
     * @param status_id
     * @param allManager
     */
    updateTaskStatus(task_id: number, status_id: number, allManager: boolean = false): Observable<Task> {
        return (
            allManager ? this.allManager$ : this.tasks$
        ).pipe(
            take(1),
            switchMap(currentTasks => this._httpClient.patch<Task>(`${environment.api}/tasks/${task_id}`, {status_id}, {
                reportProgress: true
            }).pipe(
                map((updatedTask) => {
                    // Find the index of the updated task
                    const taskIndex = allManager ? currentTasks.findIndex(item => item.type === 0 && item.id === task_id) : currentTasks.findIndex(item => item.id === task_id);

                    if (updatedTask.status.name === 'Concluída' && allManager) {
                        this._allManager.value.splice(taskIndex, 1);
                        return updatedTask;
                    }

                    // Maintain current sub-tasks from accessor
                    // updatedTask.sub_tasks = currentTasks[taskIndex].sub_tasks;

                    // Maintain current task messages from accessor
                    // currentTasks[taskIndex].taskMessages = updatedTask.taskMessages;

                    // Update the task
                    // currentTasks[taskIndex] = allManager ? {...updatedTask, type: 0} : updatedTask;

                    // Update the tasks
                    allManager ? this._allManager.next(currentTasks) : this._tasks.next(currentTasks);

                    // Return the updated task
                    return updatedTask;
                })
            ))
        );
    }

    /**
     * Update Service Order
     * @param serviceOrderId
     * @param status_id
     * @param serviceOrder
     * @param allManager
     */
    updateServiceOrderStatus(serviceOrderId: number, status_id: number, serviceOrder: ServiceOrder, allManager: boolean = false): Observable<ServiceOrder>
    {
        return (
            allManager ? this.allManager$ : this.serviceOrders$
        ).pipe(
            take(1),
            switchMap(currentServiceOrders => this._httpClient.patch<ServiceOrder>(`${environment.api}/service-order/${serviceOrderId}`, {
                status_id: status_id,
                id: serviceOrderId,
                title: serviceOrder.title,
                description: serviceOrder.description,
                value_estimated: serviceOrder.value_estimated,
                sla_id: serviceOrder.sla_id,
                type_action_id: serviceOrder.type_action_id,
                space_id: serviceOrder.space_id,
                manager_configuration_id: serviceOrder.manager_configuration_id,
                responsible_id: serviceOrder.responsible_id,
                requester_id: serviceOrder.requester_id,
                origin_id: serviceOrder.origin_id,
                category_id: serviceOrder.category_id,
            }, {
                reportProgress: true
            }).pipe(
                map((updatedServiceOrder) => {
                    // Find the index of the updated task
                    const serviceOrderIndex = allManager ? currentServiceOrders.findIndex(item => item.type === 1 && item.id === serviceOrderId) : currentServiceOrders.findIndex(item => item.id === serviceOrderId);

                    // Remove OS from list if status is completed
                    if (updatedServiceOrder.managerStatus.name === 'Concluída' && allManager) {
                        this._allManager.value.splice(serviceOrderIndex, 1);
                        return updatedServiceOrder;
                    }

                    // Update the task
                    currentServiceOrders[serviceOrderIndex] = allManager ? {...updatedServiceOrder, type: 1} : updatedServiceOrder;

                    // Update the tasks
                    allManager ? this._serviceOrders.next(currentServiceOrders) : this._serviceOrders.next(currentServiceOrders);

                    // Return the updated task
                    return updatedServiceOrder;
                })
            ))
        );
    }

    updateServiceOrderResponsible(serviceOrderId: number, responsible_id: number, serviceOrder: ServiceOrder): Observable<ServiceOrder> {
        return this.serviceOrders$.pipe(
            take(1),
            switchMap(currentServiceOrders => this._httpClient.patch<ServiceOrder>(`${environment.api}/service-order/${serviceOrderId}`, {
                status_id: serviceOrder.status_id,
                id: serviceOrderId,
                title: serviceOrder.title,
                description: serviceOrder.description,
                value_estimated: serviceOrder.value_estimated,
                sla_id: serviceOrder.sla_id,
                type_action_id: serviceOrder.type_action_id,
                space_id: serviceOrder.space_id,
                manager_configuration_id: serviceOrder.manager_configuration_id,
                responsible_id: responsible_id,
                requester_id: serviceOrder.requester_id,
                origin_id: serviceOrder.origin_id,
                category_id: serviceOrder.category_id,
            }, {
                reportProgress: true
            }).pipe(
                map((updatedServiceOrder) => {
                    // Find the index of the updated task
                    const serviceOrderIndex = currentServiceOrders.findIndex(item => item.id === serviceOrderId);

                    // Update the task
                    currentServiceOrders[serviceOrderIndex] = updatedServiceOrder;

                    // Update the tasks
                    this._serviceOrders.next(currentServiceOrders);

                    // Return the updated task
                    return updatedServiceOrder;
                })
            ))
        );
    }

    /**
     * Update task responsible
     * @param task_id
     * @param responsible_id
     */
    updateTaskResponsible(task_id: number, responsible_id: number, allManager: boolean = false): Observable<Task> {
        return (
            allManager ? this.allManager$ : this.tasks$
        ).pipe(
                take(1),
                switchMap(currentTasks => this._httpClient.patch<Task>(`${environment.api}/tasks/${task_id}`, { responsible_id }, {
                    reportProgress: true
                }).pipe(
                    map((updatedTask) => {
                        // Find the index of the updated task
                        const taskIndex = allManager ? currentTasks.findIndex(item => item.type === 0 && item.id === task_id) : currentTasks.findIndex(item => item.id === task_id);

                        // Maintain current sub-tasks from accessor
                        updatedTask.sub_tasks = currentTasks[taskIndex].sub_tasks;

/*                        // Maintain current task messages from accessor
                        updatedTask.taskMessages = currentTasks[taskIndex].taskMessages;*/

                        // Update the task
                        currentTasks[taskIndex] = allManager ? {...updatedTask, type: 0} : updatedTask;

                        // Update the tasks
                        allManager ? this._allManager.next(currentTasks) : this._tasks.next(currentTasks);

                        // Return the updated task
                        return updatedTask;
                    })
                ))
            );
    }

    /**
     * Get all space answers
     *
     * @param inspectionId
     */
    getAllSpaceAnswers(inspectionId: number): Observable<SpaceAnswer[]> {
        return this._httpClient.get<SpaceAnswer[]>(`${environment.api}/space-answers/?inspectionId=${inspectionId}`, {
            reportProgress: true
        }).pipe(
            tap((spaceAnswers) => {
                // Update Space Answers
                this._spaceAnswers.next(spaceAnswers);

                // Return Space Answers
                return spaceAnswers;

            }));
    }

    /**
     * Create sub-task
     * @param name
     * @param estimated_date
     * @param task_id
     * @param responsible
     */
    createSubTask(
        data: { name: string, estimated_date: Date, task_id: number, responsible?: number }
    ): Observable<Task> {
        return this.tasks$.pipe(
            take(1),
            switchMap(currentTasks => this._httpClient.post<Task>(`${environment.api}/sub-tasks`, data, {
                reportProgress: true
            }).pipe(
                map((createdSubTask) => {
/*                    // Find index of the updated task
                    const taskIndex = currentTasks.findIndex(item => item.id === data.task_id);*/

       /*             // Insert sub-task on array
                    currentTasks[taskIndex].sub_tasks.push(createdSubTask);

                    // Sort sub-tasks by estimated date
                    currentTasks[taskIndex].sub_tasks.sort((a, b) => (a.estimated_date > b.estimated_date ? -1 : a.estimated_date === b.estimated_date ? 0 : 1));*/

                    const taskIndex = currentTasks.findIndex(item => item.id === createdSubTask.id);

                    currentTasks[taskIndex] = createdSubTask;

                    // Update the tasks
                    this._tasks.next(currentTasks);

                    // Return the created sub-task
                    return createdSubTask;
                })
            ))
        );
    }

    /**
     * Update task responsible
     * @param sub_task_id
     * @param responsible_id
     */
    updateSubTaskResponsible(sub_task_id: number, responsible_id: number): Observable<Task> {
        return this.tasks$.pipe(
            take(1),
            switchMap(currentTasks => this._httpClient.patch<Task>(`${environment.api}/sub-tasks/${sub_task_id}`, { responsible_id }, {
                reportProgress: true
            }).pipe(
                map((updatedSubTask) => {
                    // Find the index of the updated task
                    const taskIndex = currentTasks.findIndex(item => item.id === updatedSubTask.id);

                    /*
                    // Find the index of the updated sub-task
                    const subTaskIndex = currentTasks[taskIndex].sub_tasks.findIndex(item => item.id === sub_task_id);

                    // Update the sub-task
                    currentTasks[taskIndex].sub_tasks[subTaskIndex] = updatedSubTask;
                    */

                    currentTasks[taskIndex] = updatedSubTask;

                    // Update the tasks
                    this._tasks.next(currentTasks);

                    // Return the updated sub-task
                    return updatedSubTask;
                })
            ))
        );
    }

    /**
     * Update sub-task
     * @param name
     * @param estimated_date
     * @param task_id
     * @param responsible
     */
    updateSubTask(
        data: { name?: string, estimated_date?: Date, task_id?: number, responsible_id?: number }, sub_task_id: number,
    ): Observable<Task> {
        return this.tasks$.pipe(
            take(1),
            switchMap(currentTasks => this._httpClient.patch<Task>(`${environment.api}/sub-tasks/${sub_task_id}`, data, {
                reportProgress: true
            }).pipe(
                map((updatedSubTask) => {
                    // Find the index of the updated task
                    const taskIndex = currentTasks.findIndex(item => item.id === data.task_id);

/*
                    // Find the index of the updated sub-task
                    const subTaskIndex = currentTasks[taskIndex].sub_tasks.findIndex(item => item.id === sub_task_id);

                    // Update the sub-task
                    currentTasks[taskIndex].sub_tasks[subTaskIndex] = updatedSubTask;
*/

                    currentTasks[taskIndex] = updatedSubTask;

                    // Update the tasks
                    this._tasks.next(currentTasks);

                    // Return the updated sub-task
                    return updatedSubTask;
                })
            ))
        );
    }

    /**
     * Toggle sub-task finished status
     * @param is_finished
     * @param subTaskId
     */
    toggleFinishedSubTask(is_finished: boolean, subTaskId: number): Observable<SubTask> {
        return this._httpClient.patch<SubTask>(`${environment.api}/sub-tasks/${subTaskId}`, {is_finished}, {
            reportProgress: true
        }).pipe(
            tap((subTask: SubTask) => {
                return subTask;
            })
        );
    }


    /**
     * Update space answer
     * @param spaceAnswerId
     * @param body
     */
    updateSpaceAnswer(spaceAnswerId: number, body: any): Observable<SpaceAnswer> {

        return this.spaceAnswers$.pipe(
            take(1),
            switchMap(spaceAnswers => this._httpClient.patch<SpaceAnswer>(`${environment.api}/space-answers/${spaceAnswerId}`,
                body,
                {
                    reportProgress: true
                }).pipe(
                tap((spaceAnswer) => {

                    // Find Answer Index
                    const answerIndex = spaceAnswers.findIndex(answer => answer.id === spaceAnswer.id);

                    // Update Space Answer
                    spaceAnswers[answerIndex] = spaceAnswer;

                    // Update Space Answers
                    this._spaceAnswers.next(spaceAnswers);

                    // Return space answer
                    return spaceAnswer;
                })
            ))
        );
    }

    /**
     * Create space answer file
     * @param spaceAnswerId
     * @param key
     */
    createSpaceAnswerFile(spaceAnswerId: number, key: any): Observable<SpaceAnswer[]> {
        return this.spaceAnswers$.pipe(
            take(1),
            switchMap(spaceAnswers => this._httpClient.post<SpaceAnswerFile>(`${environment.api}/space-answers-file`, {
                space_answers_id: spaceAnswerId,
                key
            }, {
                reportProgress: true
            }).pipe(
                map((file) => {
                    // Find the index of the updated space question
                    const spaceQuestionIndex = spaceAnswers.findIndex(item => item.id === spaceAnswerId);

                    // Update the space answer
                    spaceAnswers[spaceQuestionIndex].spaceAnswersFiles.push(file);

                    // Update the space answers
                    this._spaceAnswers.next(spaceAnswers);

                    // Return the updated space answer
                    return spaceAnswers;
                })
            ))
        );
    }

    /**
     * Remove space answer file
     * @param fileId
     * @param answerId
     */
    removeSpaceAnswerFile(fileId: number, answerId: number): Observable<SpaceAnswer[]> {
        return this.spaceAnswers$.pipe(
            take(1),
            switchMap(spaceAnswers => this._httpClient.delete<SpaceAnswerFile>(`${environment.api}/space-answers-file/${fileId}`, {
                reportProgress: true
            }).pipe(
                map((file) => {
                    // Find the index of the updated space question
                    const spaceQuestionIndex = spaceAnswers.findIndex(item => item.id === answerId);

                    // Find the index of the file
                    const fileIndex = spaceAnswers[spaceQuestionIndex].spaceAnswersFiles.findIndex(item => item.id === fileId);

                    // Remove the file
                    spaceAnswers[spaceQuestionIndex].spaceAnswersFiles.splice(fileIndex, 1);

                    // Update the space answers
                    this._spaceAnswers.next(spaceAnswers);

                    // Return the updated space answer
                    return spaceAnswers;
                })
            ))
        );

    }

    /**
     * Delete sub-task
     * @param subTask
     */
    deleteSubTask(subTask: SubTask): Observable<Task> {
        return this.tasks$.pipe(
            take(1),
            switchMap(currentTasks => this._httpClient.delete<Task>(`${environment.api}/sub-tasks/${subTask.id}`, {
                reportProgress: true,
            }).pipe(
                map((updatedSubTask) => {
                    // Find the index of the updated task
                    const taskIndex = currentTasks.findIndex(item => item.id === subTask.task_id);

                    currentTasks[taskIndex] = updatedSubTask;

                    // Update the tasks
                    this._tasks.next(currentTasks);

                    // Return the updated sub-task
                    return updatedSubTask;
                })
            ))
        );
    }

    /**
     * Delete Service Order
     * @param serviceOrder
     * @param allManager
     */
    deleteServiceOrder(serviceOrder: ServiceOrder, allManager: boolean = false): Observable<void> {
        return (
            allManager ? this.allManager$ : this.serviceOrders$
        ).pipe(
            take(1),
            switchMap(currentServiceOrder => this._httpClient.delete<ServiceOrder>(`${environment.api}/service-order/${serviceOrder.id}`, {
                reportProgress: true,
            }).pipe(
                map((updatedSubTask) => {
                    // Find the index of the updated task
                    const serviceOrderIndex = allManager ?
                        currentServiceOrder.findIndex(item => item.type === 1 && item.id === serviceOrder.id)
                        : currentServiceOrder.findIndex(item => item.id === serviceOrder.id);

                    // remove service order
                    currentServiceOrder.splice(serviceOrderIndex, 1);

                    // Update the tasks
                    allManager ? this._allManager.next(currentServiceOrder) : this._serviceOrders.next(currentServiceOrder);
                })
            ))
        );
    }

    /**
     * Delete Service Order Message
     * @param serviceOrderMessage
     */
    deleteServiceOrderMessage(serviceOrderMessage: ServiceOrderMessage, allManager: boolean = false): Observable<void> {
        return (
            allManager ? this.allManager$ : this.serviceOrderMessages$
        ).pipe(
            take(1),
            switchMap(currentServiceOrderMessage => this._httpClient.delete<ServiceOrderMessage>(`${environment.api}/service-order-messages/${serviceOrderMessage.id}`, {
                reportProgress: true,
            }).pipe(
                map((updatedServiceOrder) => {
                    const serviceOrders = this._serviceOrders.value;

                    // Find the index of the updated task
                    const serviceOrderMessageIndex = currentServiceOrderMessage.findIndex(item => item.id === serviceOrderMessage.id);

                    const serviceOrderIndex = allManager ? this._allManager.value.findIndex(item => item.type === 1 && item.id === serviceOrderMessage.service_order_id) : this._serviceOrders.value.findIndex(item => item.id === serviceOrderMessage.service_order_id);

                    const currentServiceOrderMessageIndex = serviceOrders[serviceOrderIndex].serviceOrderMessage.findIndex(item => item.id === serviceOrderMessage.id);

                    serviceOrders[serviceOrderIndex].serviceOrderMessage.splice(currentServiceOrderMessageIndex, 1);

                    this._serviceOrders.next(serviceOrders);

                })
            ))
        );
    }

    /**
     * Delete Task Message
     * @param taskMessage
     * @param allManager
     */
    deleteTaskMessage(taskMessage: TaskMessage, allManager: boolean = false): Observable<void> {
        return (
            allManager ? this.allManager$ : this.tasks$
        ).pipe(
            take(1),
            switchMap(current => this._httpClient.delete<void>(`${environment.api}/task-messages/${taskMessage.id}`, {
                reportProgress: true,
            }).pipe(
                map(() => {
                    // Find the index of the updated task
                    const taskIndex = allManager ? current.findIndex((item) => item.type === 0 && item.id === taskMessage.task_id) : current.findIndex(item => item.id === taskMessage.task_id);

                    // Find the index of the deleted message
                    const taskMessageIndex = current[taskIndex].taskMessages.findIndex(item => item.id === taskMessage.id);

                    // Remove the message
                    current[taskIndex].taskMessages.splice(taskMessageIndex, 1);

                    // Update the tasks
                    allManager ? this._allManager.next(current) : this._tasks.next(current);
                })
            ))
        );
    }

    /**
     * Get all periodicities
     */
    getAllPeriodicities(): Observable<Periodicity[]> {
        return this._httpClient.get<Periodicity[]>(`${environment.api}/periodicity`, {
            reportProgress: true,
        }).pipe(
            tap((periodicities) => {
                return periodicities;
            })
        );
    }

    /**
     * Get all manager items filtered
     * @param status Query for either finished or all status
     * @param search Search for query results
     * @param limit Items per fetch
     * @param filter
     * @param responsible
     * @param category
     */
    getAll(status: 'finished' | 'all' = 'all', search: string = '', limit: number = environment.pageSize, filter: string, responsible?: number, category?: number): Observable<AllManager[]>
    {
        return this._httpClient.get<AllManager[]>(`${environment.api}/tasks/all-manager?status=${status}&search=${search}&take=${limit}&filter=${filter}${responsible ? '&responsible=' + responsible : ''}${category ? '&category=' + category : ''}`, {
            reportProgress: true,
        }).
        pipe(
            tap((items) => {
                // Return items
                return items;
            })
        );
    }

    /**
     * Delete Service Order Item
     * @param serviceOrderMessage
     */
    deleteServiceOrderItem(serviceOrderItem: ServiceOrderItem): Observable<ServiceOrder> {
        return this.serviceOrders$.pipe(
            take(1),
            switchMap(currentServiceOrder => this._httpClient.delete<ServiceOrder>(`${environment.api}/service-order-item/${serviceOrderItem.id}`, {
                reportProgress: true,
            }).pipe(
                map((updatedServiceOrder) => {

                    // Find the index of the updated task
                    const serviceOrderIndex = currentServiceOrder.findIndex(item => item.id === serviceOrderItem.service_order_id);
                    const serviceOrderItemIndex = currentServiceOrder[serviceOrderIndex].serviceOrderItem.findIndex(item => item.id === serviceOrderItem.id);

                    // remove service order item
                    currentServiceOrder[serviceOrderIndex].serviceOrderItem.splice(serviceOrderItemIndex, 1);

                    currentServiceOrder[serviceOrderIndex] = updatedServiceOrder;

                    // Update service order item
                    this._serviceOrders.next(currentServiceOrder);

                    return updatedServiceOrder;
                })
            ))
        );
    }

    /**
     * Create Service Item
     *
     */
    createServiceOrderItem(serviceOrderItem: ServiceOrderItem[]): Observable<void> {
        return this.serviceOrders$.pipe(
            take(1),
            switchMap((currentServiceOrder) => this._httpClient.post<ServiceOrder>(`${environment.api}/service-order-item/bulk-create`, serviceOrderItem, {
                reportProgress: true,
            }).pipe(
                map((newServiceOrderItem) => {
                    // Find the index of the updated task
                    const serviceOrderIndex = currentServiceOrder.findIndex(item => item.id === serviceOrderItem[0].service_order_id);

                    currentServiceOrder[serviceOrderIndex] = newServiceOrderItem;

                    // Update service order item
                    this._serviceOrders.next(currentServiceOrder);
                })
            ))
        );
    }

    /**
     * Update Service Item
     *
     */
    updateServiceOrderItem(serviceOrderChange: ServiceOrderItem[]): Observable<void> {
        return this.serviceOrders$.pipe(
            take(1),
            switchMap(serviceOrderItems => this._httpClient.patch<ServiceOrder>(`${environment.api}/service-order-item/`,
                serviceOrderChange,
                {
                    reportProgress: true
                }).pipe(
                map((updateServiceOrderItem) => {
                    // Find the index of the updated task
                    const serviceOrderIndex = serviceOrderItems.findIndex(item => item.id === serviceOrderChange[0].service_order_id);

                    serviceOrderItems[serviceOrderIndex] = updateServiceOrderItem;

                    // Update service order item
                    this._serviceOrders.next(serviceOrderItems);

                })
            ))
        );
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Calendar Services
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get calendar events
     * @param month
     * @param year
     * @param replace = false
     */
    getEvents(month: number, year: number, replace = false): Observable<CalendarEvent[]>
    {
        // Get the events
        return this._httpClient.get<CalendarEvent[]>(`${environment.api}/tasks/calendar`, {
            reportProgress: true,
            params: {
                month: String(month),
                year: String(year)
            }
        }).pipe(
            switchMap(response => this._events.pipe(
                take(1),
                map((events: CalendarEvent[]) => {
                    if (replace)
                    {
                        // Execute the observable with the response replacing the events object
                        this._events.next(response);
                    }
                    else
                    {
                        // If events is null, replace it with an empty array
                        events = events || [];

                        // Execute the observable by appending the response to the current events
                        this._events.next([...events, ...response]);
                    }

                    return response;
                })
            ))
        );
    }

    /**
     * Get calendar recurrent events
     */
    getRecurrentEvents(): Observable<CalendarEvent[]>
    {
        // Get the events
        return this._httpClient.get<CalendarEvent[]>(`${environment.api}/tasks/calendar-recurrent`, {
            reportProgress: true,
        }).pipe(
            switchMap(response => this._events.pipe(
                take(1),
                map((recurrentEvents: CalendarEvent[]) => {
                    // Update the events
                    this._recurrentEvents.next(response);

                    return response;
                })
            ))
        );
    }

    /**
     * Get all categories
     *
     * @param type optional -> 0 = task, 1 = os, other or no value = all
     * @param page default = 1
     * @param limit default = 9999
     *
     * @return List of categories filtered by type of without filter if type is not passed in as argument
     */
    getAllCategories(type?: number, page: number = 1, limit: number = 9999): Observable<ServiceOrderCategoryPaginate>
    {
        return this._httpClient.get<ServiceOrderCategoryPaginate>(`${environment.api}/service-order-categories?page=${page}&limit=${limit}${type ? '&type=' + type : ''}`, {
            reportProgress: true,
        }).pipe(
            tap((paginate) => {
                return paginate;
            })
        );
    }
}

