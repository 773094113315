<!-- Notifications toggle -->
<button
    mat-icon-button
    (click)="openPanel()"
    #notificationsOrigin>
<!--    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">-->
<!--        <span-->
<!--            class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-400 text-indigo-50 text-xs font-medium"-->
<!--            *ngIf="unreadCount > 0">{{unreadCount}}</span>-->
<!--    </span>-->
    <mat-icon class="text-blue-600 icon-size-6" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded overflow-hidden shadow-lg" [ngClass]="{'top-8': isIos}">

        <!-- Header -->
        <div class="flex-shrink-0 w-full h-1 bg-blue-600"></div>
        <div class="flex flex-shrink-0 items-center py-3 pr-4 pl-6 border-b bg-gray-100 dark:bg-gray-800">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Notificações</div>
            <!--            <div class="ml-auto">-->
            <!--                <button-->
            <!--                    mat-icon-button-->
            <!--                    [matTooltip]="'Marcar tudo como lido'"-->
            <!--                    [disabled]="unreadCount === 0"-->
            <!--                    (click)="markAllAsRead()">-->
            <!--                    <mat-icon-->
            <!--                        class="icon-size-5"-->
            <!--                        [svgIcon]="'heroicons_solid:mail-open'"></mat-icon>-->
            <!--                </button>-->
            <!--            </div>-->
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">

            <!-- Notifications -->
            <ng-container *ngFor="let notification of notifications">

                <div
                    class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !notification.visualizada}">

                    <!-- Notification with a link -->
                    <ng-container>
                        <!-- Normal links -->
                        <a class="flex flex-auto py-5 pl-6 cursor-pointer" *ngIf="notification.origem == 'mural'" [routerLink]="'/mural-de-avisos'">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>
                        <!-- Ecomenda links -->
                        <a class="flex flex-auto py-5 pl-6 cursor-pointer" *ngIf="notification.origem == 'encomenda'" [routerLink]="'/encomendas'">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>
                        <!-- Router links -->
                        <a class="flex flex-auto py-5 pl-6 cursor-pointer" *ngIf="notification.origem == 'documento-administrativo'" [href]="'https://s3-sa-east-1.amazonaws.com/cipa-visualizador-documentos/' + env.profile + '/index.html?id=' + notification.identificacao" target="_blank">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>
                    </ng-container>

                    <!-- Notification without a link -->
                    <!--                    <span-->
                    <!--                        class="flex flex-auto py-5 pl-6">-->
                    <!--                        <ng-container *ngTemplateOutlet="notificationContent"></ng-container>-->
                    <!--                    </span>-->

                    <!-- Actions -->
                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Indicator -->
                        <!--                        <button-->
                        <!--                            class="w-6 h-6 min-h-6"-->
                        <!--                            mat-icon-button-->
                        <!--                            (click)="toggleRead(notification)"-->
                        <!--                            [matTooltip]="notification.visualizada ? 'Marcar como não lido' : 'Marcar como lido'">-->
                        <!--                            <span-->
                        <!--                                class="w-2 h-2 rounded-full"-->
                        <!--                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.visualizada,-->
                        <!--                                              'bg-teal-600': !notification.visualizada}"-->
                        <!--                            ></span>-->
                        <!--                        </button>-->
                        <!-- Remove -->
                        <!--                        <button-->
                        <!--                            class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"-->
                        <!--                            mat-icon-button-->
                        <!--                            (click)="delete(notification)"-->
                        <!--                            [matTooltip]="'Remover'">-->
                        <!--                            <mat-icon-->
                        <!--                                class="icon-size-4"-->
                        <!--                                [svgIcon]="'heroicons_solid:x'"></mat-icon>-->
                        <!--                        </button>-->
                    </div>

                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <div class="flex flex-shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full">
                        <mat-icon *ngIf="notification.origem == 'mural'" class="icon-size-5" [svgIcon]="'awesome_solid:envelope-open-text'"></mat-icon>
                        <mat-icon *ngIf="notification.origem == 'documento-administrativo'" class="icon-size-5" [svgIcon]="'awesome_solid:book'"></mat-icon>
                        <mat-icon *ngIf="notification.origem == 'encomenda'" class="icon-size-5" [svgIcon]="'awesome_solid:box'"></mat-icon>
                    </div>
                    <!-- Image -->
                    <!--                    <img-->
                    <!--                        class="flex-shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"-->
                    <!--                        *ngIf="notification.image"-->
                    <!--                        [src]="notification.image"-->
                    <!--                        [alt]="'Notification image'">-->
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <!--                        <div class="font-semibold line-clamp-1" *ngIf="notification.title" [innerHTML]="notification.title"></div>-->
                        <div class="line-clamp-2 text-gray-800 font-medium">{{notification.texto}}</div>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{notification.data}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!notifications || !notifications.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon
                            class="text-primary-700"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">Sem notificações</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Quando você tiver notificações, elas aparecerão aqui.</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
