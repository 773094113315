import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CipaFacilMediaWatcherModule } from '@inovite/services/media-watcher/media-watcher.module';
import { CipaFacilSplashScreenModule } from '@inovite/services/splash-screen/splash-screen.module';
import { CipaFacilTailwindConfigModule } from '@inovite/services/tailwind/tailwind.module';
import { CipaFacilUtilsModule } from '@inovite/services/utils/utils.module';
import {CipaFacilConfirmationModule} from '@inovite/services/confirmation/confirmation.module';

@NgModule({
    imports  : [
        CipaFacilConfirmationModule,
        CipaFacilMediaWatcherModule,
        CipaFacilSplashScreenModule,
        CipaFacilTailwindConfigModule,
        CipaFacilUtilsModule
    ],
    providers: [
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class InoviteModule
{
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: InoviteModule)
    {
        if ( parentModule )
        {
            throw new Error('InoviteModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
