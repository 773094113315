<div *ngIf="position === 'top'" class="relative w-full bg-default py-6 px-6 xl:px-0 shadow-sm hidden md:block">
    <div class="max-w-screen-xl mx-auto">
        <div class="flex items-center">
            <div class="w-full flex items-center justify-between">
                <div *ngFor="let mn of menu" [routerLink]="[mn.link]" class="cursor-pointer flex items-center">
                    <img class="h-6 mr-2 w-auto" src="/assets/icons/{{mn.icon}}.svg" alt="">
                    <span class="text-white font-bold text-sm">{{mn.name}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="position === 'middle'" class="md:hidden grid grid-cols-1 grid-cols-2 gap-y-4 gap-x-4 w-full mt-4">
    <div *ngFor="let mn of menu" [routerLink]="[mn.link]" class="w-full bg-default cursor-pointer flex items-center px-6 py-4 rounded-lg shadow-default">
        <img class="h-6 mr-2 w-auto" src="/assets/icons/{{mn.icon}}.svg" alt="">
        <span class="text-white font-bold text-sm">{{mn.name}}</span>
    </div>
</div>
