import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {
    NoticeBoard,
    NoticeBoardMonth,
    NoticeBoardMonthPaginate,
    NoticeBoardPaginate,
    NoticeBoardProfile, NoticeBoardProfilePaginate
} from './notice-board.types';
import {CondominiumService} from '../condominium/condominium.service';
import {UserNode} from '../../core/user/user.model';
import {UserService} from '../../core/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NoticeBoardService
{
    private _notice: BehaviorSubject<NoticeBoard | null> = new BehaviorSubject(null);
    private _notices: BehaviorSubject<NoticeBoard[] | null> = new BehaviorSubject([]);
    private _months: BehaviorSubject<NoticeBoardMonth[] | null> = new BehaviorSubject([]);
    private _profiles: BehaviorSubject<NoticeBoardProfile[] | null> = new BehaviorSubject([]);

    private _noticesPaginate: BehaviorSubject<NoticeBoardPaginate | null> = new BehaviorSubject(null);

    private _user: UserNode;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _condominiumService: CondominiumService,
        private _userService: UserService
    ) {
        this._userService.user$
            .subscribe((user) => {
                this._user = user;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notice
     */
    get notice$(): Observable<NoticeBoard> {
        return this._notice.asObservable();
    }

    /**
     * Getter for notices
     */
    get notices$(): Observable<NoticeBoard[]> {
        return this._notices.asObservable();
    }


    /**
     * Getter for notices
     */
      get noticesPaginate$(): Observable<NoticeBoardPaginate> {
        return this._noticesPaginate.asObservable();
    }

    /**
     * Getter for notices months
     */
    get months$(): Observable<NoticeBoardMonth[]> {
        return this._months.asObservable();
    }

    /**
     * Getter for notices profiles
     */
    get profiles$(): Observable<NoticeBoardProfile[]> {
        return this._profiles.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notices
     */
    getAll(month: string = null, pagina: number = 0): Observable<NoticeBoardPaginate>
    {
        // Profile list verification
        const occupation = this._user.occupation.code;
        const profileList = this.getProfile(occupation);

        return this._httpClient.post<NoticeBoardPaginate>(`${environment.api}/api/mural/posts/paginado`, {
            codCondominio: this._user.condominium_active.condominium.code,
            mesRef: month,
            pagina: pagina,
            limite: 20,
            perfilList: profileList
        }).pipe(
            tap((paginate: NoticeBoardPaginate) => {
                // Update notices
                this._notices.next(paginate.posts);

                // Return notices
                return paginate;
            })
        );
    }

    /**
     * Create notices
     *
     * @param notice
     */
    create(notice: any): Observable<any>
    {
        return this.notices$.pipe(
            take(1),
            switchMap((notices) => this._httpClient.post<any>(`${environment.api}/api/mural/post/novo`, notice).pipe(
                map((newNotice) => {

                    // Update product with the new notice
                    this._notices.next([...notices, newNotice]);

                    // Return the new notice
                    return newNotice;
                })
            ))
        );
    }

    /**
     * Get all notices months
     */
    getMonths(): Observable<NoticeBoardMonthPaginate>
    {
        return this._httpClient.post<NoticeBoardMonthPaginate>(`${environment.api}/api/mural/posts/meses`, {
            codCondominio: this._user.condominium_active.condominium.code,
        }).pipe(
            tap((paginate: NoticeBoardMonthPaginate) => {
                // Update notices months
                this._months.next(paginate.data);

                // Return notices months
                return paginate;
            })
        );
    }

    /**
     * Get all notices profiles
     */
    getProfiles(): Observable<NoticeBoardProfilePaginate>
    {
        return this._httpClient.get<NoticeBoardProfilePaginate>(`${environment.api}/api/mural/perfil-list`).pipe(
            tap((paginate: NoticeBoardProfilePaginate) => {
                // Update notices months
                this._profiles.next(paginate.data);

                // Return notices months
                return paginate;
            })
        );
    }

    getProfile(type: string): string
    {
        const map = {
            'admin': ['SINDICO', 'CONDOMINO', 'CONSELHEIRO', 'INQUILINO'],
            'sindico': ['SINDICO'],
            'condomino': ['CONDOMINO'],
            'funcionario': ['CONDOMINO'],
            'conselheiro': ['CONSELHEIRO'],
            'inquilino': ['INQUILINO'],
            'subsindico': ['SINDICO'],
            'concierge': ['CONDOMINO'],
            'advogado': ['CONDOMINO'],
            'consultor': ['SINDICO', 'CONDOMINO', 'CONSELHEIRO', 'INQUILINO'],
            'assessor': ['SINDICO', 'CONDOMINO', 'CONSELHEIRO', 'INQUILINO'],
            'outros': ['CONDOMINO'],
            'preposto': ['SINDICO'],
            'administradora': ['CONDOMINO'],
            'administrador': ['CONDOMINO'],
            'morador': ['CONDOMINO'],
            'gestor-operacional': ['SINDICO'],
            'cipa-sindica': ['SINDICO'],
        };

        return map[type] ? map[type] : 'undefined';
    }
}
