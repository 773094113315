export const environment = {
    api: '195.35.17.198:3000',
    profile: 'hom',
    production: false,
    hotJarCode: '',
    pageSize: 25,
    pageSizeOptions: [5, 10, 25, 50, 100],
    pdfMaxPages: 50,
    sizeFiles: 20000000,
    acceptFiles: 'image/jpeg,image/jpg,image/png,application/pdf',
    quillModule: {
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction
                [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'font': []}],
                [{'align': []}],
                ['clean'],                                         // remove formatting button
                ['link', 'image']
            ]
        }
    }
};

