<div class="" [ngClass]="{'mb-16 md:mb-0': !notices || notices && !notices.length}">
    <div *ngIf="notices && notices.length" class="relative h-100 bg-blue-800 mt-16 md:mt-0 md:px-6">

        <div class="absolute left-1 md:left-10 flex items-center justify-center h-full z-90">
            <button *ngIf="index > 0" (click)="previous()" mat-icon-button class="bg-white shadow-default">
                <mat-icon class="icon-size-5 text-white" [svgIcon]="'awesome_solid:angle-left'"></mat-icon>
            </button>
        </div>

        <div class="absolute right-1 md:right-10 flex items-center justify-center h-full z-90">
            <button *ngIf="index < notices.length - 1" (click)="next()" mat-icon-button class=" bg-white shadow-default">
                <mat-icon class="icon-size-5 text-white " [svgIcon]="'awesome_solid:angle-right'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-wrap h-full w-full md:max-w-screen-xl md:mx-auto z-50 ">

            <div class="flex items-center w-full h-100 md:px-4">
                <div class="w-full md:w-5/12 md:pr-2 hidden md:block sm:pl-16">
                    <div class="flex flex-col my-auto">
                        <div class="w-full text-white font-light">Mural de avisos</div>
                        <div class="w-full text-xl mt-3 text-white font-semibold uppercase">{{notices[index].titulo}}</div>
                        <div class="w-full text-sm text-white font-semibold uppercase">{{notices[index].data | date: 'dd/MM/yy'}}</div>
                        <div class="w-full text-sm mt-3 text-white font-semibold description" [innerHtml]="notices[index].mensagem"></div>
                    </div>
                </div>

                <div class="w-full md:w-2/12 md:px-2 hidden md:flex mt-50">
                    <div (click)="redirect()" class="w-full mt-3 ion-text-center text-white font-bold cursor-pointer ">
                        <button class="bg-default font-bold text-white text-center rounded-full py-3 mt-4 md:mt-6 px-6" mat-flat-button [color]="'primary'">
                            VER MAIS
                        </button>
                    </div>
                </div>

                <div class="w-full md:w-5/12 md:flex">
                    <img class="object-cover h-100" [src]="notices[index].urlImagemCapa">
                </div>
                <div class=" md:w-6/12 md:pr-2 md:hidden textMobile absolute ">
                    <div class="flex flex-col my-auto py-4 z-50">
                        <div class="w-full text-white font-light  text-center z-100"><p class="">Mural de avisos</p></div>
                        <div class="w-full text-xl mt-3 text-white font-semibold uppercase  text-center  ">{{notices[index].titulo}}</div>
                        <div class="w-full text-sm text-white font-semibold uppercase  text-center ">{{notices[index].data | date: 'dd/MM/yy'}}</div>
                        <div class="w-full text-sm mt-3 text-white font-semibold break-words description text-center px-10 ion-text-wrap" [innerHtml]="notices[index].mensagem"></div>
                        <div (click)="redirect()" class="w-full mt-3 ion-text-center text-white font-bold cursor-pointer buttonMobile">
                            <button class="bg-default font-bold text-white text-center rounded-full py-3 mt-4 md:mt-6 px-6 shadow-default" mat-flat-button [color]="'primary'">
                                VER MAIS
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="notices?.length <= 0" class="relative mt-16 md:mt-0 md:px-6">
        <div class="">

        </div>
    </div>

</div>
