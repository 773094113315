import { NgModule } from '@angular/core';
import { CipaFacilScrollbarDirective } from '@inovite/directives/scrollbar/scrollbar.directive';

@NgModule({
    declarations: [
        CipaFacilScrollbarDirective
    ],
    exports     : [
        CipaFacilScrollbarDirective
    ]
})
export class CipaFacilScrollbarModule
{
}
