<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <cipafacil-vertical-navigation
        class="bg-white"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="verticalNavigation"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container cipafacilVerticalNavigationContentHeader="">
            <!-- Logo -->
            <div [routerLink]="['/dashboard']" class="cursor-pointer flex items-start h-20 pl-6 pt-6">
                <img class="w-32" src="assets/images/logo/logo-cipa.png" alt="Logo image">
            </div>
        </ng-container>
    </cipafacil-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <div class="fixed md:relative z-99 w-full shadow">

        <!-- Iphone Notch -->
        <div *ngIf="isIos" class="bg-card min-h-8 h-8 w-full -mb-1"></div>

        <!-- Header -->
        <div class="flex flex-col flex-0 justify-center w-full h-16 overflow-hidden sm:h-10 md:h-18">
            <!-- Top bar -->
            <div class="relative flex flex-auto justify-center w-full px-4 md:px-8 bg-white border-b">
                <div class="flex items-center w-full max-w-360 h-16 sm:h-20">
                    <!-- Logo -->
                    <ng-container *ngIf="!isScreenSmall">
                        <div [routerLink]="['/dashboard']" class="flex cursor-pointer items-center">
                            <img class="w-32" src="assets/images/logo/logo-cipa.png">
                        </div>
                    </ng-container>
                    <!-- Navigation toggle button -->
                    <ng-container *ngIf="isScreenSmall">
                        <button *ngIf="isDashboard" mat-icon-button (click)="toggleNavigation('mainNavigation')">
                            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                        </button>

                        <div *ngIf="!isDashboard" class="flex items-center">
                            <button (click)="back()" mat-icon-button>
                                <mat-icon class="icon-size-4.5" [svgIcon]="'awesome_solid:arrow-left'"></mat-icon>
                            </button>

                            <h2 class="text-xl font-medium text-blue-800 tracking-tight leading-7 my-0 truncate ml-2">{{title}}</h2>
                        </div>
                    </ng-container>

                    <div *ngIf="!isScreenSmall || (isScreenSmall && isDashboard)" class="flex items-center pl-2 ml-auto space-x-2">
                        <checkin></checkin>
                        <notifications></notifications>
                        <user-menu></user-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <quick-access *ngIf="hasCondominium" [position]="'top'"></quick-access>
    <!-- Content -->
    <div class="flex flex-auto justify-center bg-white w-full">
        <div class="flex flex-col flex-auto w-full max-w-screen-xl pb-20 md:pb-0" [ngClass]="{'mt-8': isIos}">
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

</div>
