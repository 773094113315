// Interval of tasks
export enum TaskIntervals {
    'Diária' = 1,
    'Semanal' = 1,
    'Mensal' = 1,
    'Bimestral' = 2,
    'Trimestral' = 3,
    'Semestral' = 6,
    'Anual' = 1,
}

// Recurrence of tasks
export enum TaskRecurrences {
    'Diária' = 'daily',
    'Semanal' = 'weekly',
    'Mensal' = 'monthly',
    'Bimestral' = 'monthly',
    'Trimestral' = 'monthly',
    'Semestral' = 'monthly',
    'Anual' = 'yearly',
}
