<div class="p-4">
    <div class="text-blue-700 font-bold">{{title}}</div>
    <div class="font-medium mt-2">{{message}}</div>
    <div class="flex items-center justify-between mt-14">
        <div class="cursor-pointer border border:bg-blue-500 hover:bg-blue-500 hover:text-white py-2 px-6 font-semibold text-blue-500 rounded-full" (click)="onConfirm()">
            {{cancelButtonText}}
        </div>

        <div class="cursor-pointer bg-blue-500 hover:bg-blue-700 py-2 px-6 font-semibold text-white rounded-full" (click)="onConfirm(true)">
            {{confirmButtonText}}
        </div>
    </div>
</div>
