<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- App layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<app-layout *ngIf="layout === 'app'"></app-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- App layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<admin-layout *ngIf="layout === 'admin'"></admin-layout>

